import './SideBar.css';
import Logo from '../../../images/logo1.png'
import cicko from '../../../images/cicko1.png'
import { NavLink, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StyleIcon from '@mui/icons-material/Style';
import EventIcon from '@mui/icons-material/Event';
import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../../images/logo1.png';
import LogoutIcon from '@mui/icons-material/Logout';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

const panelSubs = [
    { id: 1, name: 'Dashboard', link: '/adminpanel/dashboard', icon: DashboardIcon },
    { id: 2, name: 'Menu', link: '/adminpanel/menu', icon: MenuBookIcon },
    { id: 3, name: 'Reservation', link: '/adminpanel/reservation', icon: StyleIcon },
    { id: 4, name: 'Events', link: '/adminpanel/events', icon: EventIcon },
    { id: 5, name: 'Banners', link: '/adminpanel/banners', icon: ViewCarouselIcon },
];



function SideBar() {
    const location = useLocation();
    const [activeLinkId, setActiveLinkId] = useState(1);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const currentPage = panelSubs.find((page) => page.link === location.pathname);
        if (currentPage) {
            setActiveLinkId(currentPage.id);
        }
    }, [location.pathname]);

    const openNav = (id) => {
        setActiveLinkId(id);
        setShow(false)
    };


    return (
        <div className="sideBarC">
            <div className='leftSide'>
                <div className='imgLogoA'>
                    <img src={Logo} onClick={() => window.location.pathname = "/adminpanel/dashboard"} alt="" />
                </div>
                <div className='navsAdminP'>
                    {panelSubs.map((val, key) => {
                        return (
                            <NavLink onClick={() => openNav(val.id)} className={activeLinkId === val.id ? 'activeLinkAdmin' : ''} key={key} to={val.link} >
                                {val.icon && <val.icon />}
                                {val.name}
                            </NavLink>
                        )
                    })}
                </div>
            </div>
            <div className='burgerMobile' onClick={handleShow}>
                <MenuIcon />
            </div>

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id='titleMob'> <div className='divLogoMobile'> <img src={logo} onClick={() => window.location.pathname = "/adminpanel/dashboard"} className='logoMobile' alt="" /></div></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='navsAdminPP'>
                        <div className='insAd'>
                            {panelSubs.map((val, key) => {
                                return (

                                    <NavLink onClick={() => openNav(val.id)} className={activeLinkId === val.id ? 'activeLinkAdminM' : ''} key={key} to={val.link} >
                                        {val.icon && <val.icon />}
                                        {val.name}
                                    </NavLink>

                                )   
                            })}
                        </div>
                        <NavLink>
                            <LogoutIcon />
                            LogOut
                        </NavLink>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <div className='rightSide'>
                <img src={cicko} alt="" />
            </div>
        </div>
    );
}

export default SideBar;
