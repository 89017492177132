import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   banners: null
};

const MenuSlice = createSlice({
    name: "menu",
    initialState: initialState,
    reducers: {
        setBanners: (state, action) => {
            state.banners = action.payload;
        },
    }
});

export const {setBanners} = MenuSlice.actions;

export default MenuSlice.reducer;