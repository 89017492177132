import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import axiosInstance from '../../redux/axios';
import { useParams } from 'react-router-dom';

function CreateCategory({showCategory, setShowCategory, categories, clearEdit, editCategory, updateCategory}){
     const params = useParams();
    const handleClose = () =>{
        clearEdit();
        setShowCategory(false);
    };

    const [itemName, setItemName] = useState('');
    const [itemSlug, setItemSlug] = useState('');
    const [itemPosition, setItemPosition] = useState('');
    const [itemCategory, setItemCategory] = useState(-1);
    const [isActive, setIsActive] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setErrMsg('')
        setSuccess(false)
    }, [itemName, itemSlug, itemCategory])

    useEffect(() => {
        if(editCategory){
            setItemName(editCategory.title)
            setItemSlug(editCategory.slug)
            setItemPosition(editCategory.position)
            setIsActive(editCategory.is_active)

            if(params?.categoryId){
                setItemCategory(params?.categoryId)
            }else{
                setItemCategory(-1)
            }
        }else{
            setItemName('')
            setItemSlug('')
            setItemPosition('')
            setIsActive(true)
            setItemCategory(-1)
        }
        setErrMsg('')
        setSuccess(false)
    }, [editCategory])

    const createC = async () => {
        setSuccess(false)
        try{
            if(itemName !== '' && itemSlug !== '' && itemPosition !== ''){
                let data = new FormData();
                
                data.append('title', itemName)
                data.append('slug', itemSlug)
                data.append('position', itemPosition)
                data.append('is_active', isActive ? 1 : 0)

                if(itemCategory !== -1){
                    data.append('parent_id', itemCategory)
                }

                const response = await axiosInstance.post('admin/menu-categories', data)
             
                if(response.status === 201){
                    if(response?.data?.data){
                        updateCategory(response?.data?.data, itemCategory, 'create')
                    }
                    setErrMsg('')
                    setSuccess(true)
                }
            }else{
                setErrMsg('Fill in the form')
        }
        }catch(err){
            if(!err?.response){
                setErrMsg('No server response. Try again later');
            }else if (err.response?.data?.message){
                setErrMsg(err.response?.data?.message)
            }else{
                setErrMsg('Failed to create category')
            }
        }
    }

    const editC = async () => {
        setSuccess(false)
        try{
            if(itemName !== '' && itemSlug !== '' && itemPosition !== ''){
                let str = '?';
                
                str+='title='+ itemName;
                str+='&slug='+ itemSlug;
                str+='&is_active='+ (isActive ? 1 : 0);

                str+='&parent_id='+ itemCategory;
                
                str+='&position='+ itemPosition;

                const response = await axiosInstance.put('admin/menu-categories/'+editCategory.id + str)

                if(response.status === 200){
                    setErrMsg('')
                    setSuccess(true)
                    if(response?.data?.data){
                        updateCategory(response?.data?.data, itemCategory, 'edit')
                    }
                }
            }else{
                setErrMsg('Fill in the form')
        }
        }catch(err){
            if(!err?.response){
                setErrMsg('No server response. Try again later');
            }else if (err.response?.data?.message){
                setErrMsg(err.response?.data?.message)
            }else{
                setErrMsg('Failed to create category')
            }
        }
    }
    

    return(
        <Modal show={showCategory} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>{editCategory ? 'Edit ' + editCategory.title  : 'Add Menu Category'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3 menu_create_group" size="sm" controlId="exampleForm.ControlInput1">
                        <Form.Label>Title <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={itemName} onChange={(e) => setItemName(e.target.value)} type="text" placeholder="ex. Food" />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Slug <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={itemSlug} onChange={(e) => setItemSlug(e.target.value)} type="text" placeholder="ex. food" />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Position <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={itemPosition} onChange={(e) => setItemPosition(e.target.value)} type="number" placeholder="ex. 1" min={0} />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Category <span className='errMsg reg_txt ps-1'>(if it's a sub category)</span></Form.Label>
                        <Form.Select value={itemCategory} onChange={(e) => {setItemCategory(e.target.value)}} aria-label="Default select example">
                            <option value={-1}></option>
                            {categories &&
                                categories.filter(x => !x.parent).map(category => (
                                    <option
                                        key={category.slug}
                                        value={category.id}
                                    >
                                        {category.title}
                                    </option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlTextarea1">
                        <Form.Check // prettier-ignore
                            type="switch"
                            checked={isActive}
                            onChange={(e) => {setIsActive(e.target.checked)}}
                            id="active_switch"
                            label="Is Active"
                        />
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => editCategory ? editC() : createC()}>
                    {editCategory ? 'Edit' : 'Create'}
                </Button>
                
            </Modal.Footer>
            {errMsg !== '' && <p className='errMsg center__err'>{errMsg}</p>}
            {success && <p className='succMsg center__err'>Category {editCategory ? ' Updated' : ' Created'} </p>}
        </Modal>
    )
}

export default CreateCategory;