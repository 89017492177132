import { Button, Modal } from "react-bootstrap";
import axiosInstance from "../../redux/axios";
import { useParams } from "react-router-dom";

function DeleteItem({showDelete, setShow, type, item, clearEdit, updateC}){
    const { categoryId } = useParams();
    const handleClose = () => {
        setShow(false)
        clearEdit()
    }

    const deleteC = async () => {
        if(type === 'event'){
            try{
                const response = await axiosInstance.delete('admin/events/'+item.id);
                if(response?.status === 200){
                    updateC(item, 'delete');
                    handleClose();
                }
            }catch{}
        }
        if(type === 'category'){
            try{
                const response = await axiosInstance.delete('admin/menu-categories/'+item.id);
                if(response?.status === 200){
                    let index = categoryId ? categoryId : -1
                   
                    updateC(item, index, 'delete');
                    handleClose();
                }
            }catch{}
        }
        if(type === 'item'){
            try{
                const response = await axiosInstance.delete('admin/menu-category-items/'+item.id);
                if(response?.status === 200){
                    updateC(item, 'delete');
                    handleClose();
                }
            }catch{}
        }
        if(type === 'banner'){
            try{
                const response = await axiosInstance.delete('admin/banners/'+item.id);
                if(response?.status === 200){
                    updateC(item, 'delete');
                    handleClose();
                }
            }catch{}
        }
    }

    return(
        <Modal show={showDelete} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
        centered>
            <Modal.Body>
                {item ? 'Are you sure you want to delete ' + item.title +' ?' : ''}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="danger" onClick={() => deleteC()}>
                    Delete
                </Button>
                
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteItem;