import './Navigation.css';
import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../images/logo1.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


const pages = [
    { id: 1, name: 'Home', link: '/' },
    { id: 2, name: 'Menu', link: '/menu' },
    { id: 3, name: 'Reservation', link: '/reservation' },
    { id: 4, name: 'Contact', link: '/contact' },
    { id: 5, name: 'About', link: '/aboutUs' },
];

function Navigation() {
    const location = useLocation();
    const [activeLinkId, setActiveLinkId] = useState(1);

    useEffect(() => {
        const currentPage = pages.find((page) => page.link === location.pathname);
        if (currentPage) {
            setActiveLinkId(currentPage.id);
        }
    }, [location.pathname]);

    const openNav = (id) => {
        setActiveLinkId(id);
        setShow(false)
    };

    const [show, setShow] = useState(false);

    const handleCloseMenu = () => setShow(false);
    const handleShowMenu = () => setShow(true);

    return (
        <div className="Navigation">
            <div className='relNav'>
                <div className='divLogo'> <img src={logo} onClick={() => window.location.pathname = "/"} className='logo' alt="" /></div>
                <div className='navsAndIcons'>
                    <div className='navs'>
                        {pages.map((val, key) => {
                            return (
                                <NavLink key={key} to={val.link} onClick={() => openNav(val.id)} className={activeLinkId === val.id ? 'activeLink' : ''}>
                                    {val.name}
                                </NavLink>
                            )
                        })}
                    </div>
                    <div className='Icons'>
                        <InstagramIcon onClick={() => {window.open('https://www.instagram.com/emeraldbgd', '_blank');}} />
                        {/* 
                        <FacebookIcon />
                        <XIcon />*/}
                    </div>
                </div>

                {/* MOBILE */}

                <div className='burgerBtn'>
                    <MenuOpenIcon onClick={handleShowMenu} />
                </div>
                <Offcanvas id="menuMob" show={show} onHide={handleCloseMenu} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <div className='divLogoMobile'> <img src={logo} onClick={() => window.location.pathname = "/"} className='logoMobile' alt="" /></div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='navsAndIconsMobile'>
                            <div className='navsMobile'>
                                {pages.map((val, key) => {
                                    return (
                                        <NavLink key={key} to={val.link} onClick={() => openNav(val.id)} className={activeLinkId === val.id ? 'activeLink' : ''}>
                                            {val.name}
                                        </NavLink>
                                    )
                                })}
                            </div>
                            <div className='IconsMobile'>
                                <InstagramIcon />
                                {/* 
                                <FacebookIcon />
                                <XIcon />*/}
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

            </div>
        </div>
    );
}

export default Navigation;
