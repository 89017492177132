import { Dropdown } from "react-bootstrap";
import AddIcon from '@mui/icons-material/Add';
import CreateBanner from "../Menu/CreateBanner";
import { useEffect, useState } from "react";
import axiosInstance from "../../redux/axios";
import DeleteItem from "../Menu/DeleteItem";

function BannerPage(){
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [banners, setBanners] = useState(null);
    const [editBanner, setEditBanner] = useState(null)

    useEffect(() => {
        getBanners();
    }, []);

    const getBanners = async () => {
        try{
            const response = await axiosInstance.get('admin/banners');
            if(response?.data?.data){
                setBanners(response?.data?.data)
            }
        }catch{}
    }

    const clearEdit = () => {
        setEditBanner(null)
    }

    const updateState = (data, type) => {
        if(type === 'create'){
            let temp = [...banners]
            temp.push(data);
            setBanners(temp)
        }
        if(type === 'edit'){
            let temp = [...banners]
            let index = banners.findIndex(x => x.id === data.id);
            if(index !== -1){
                temp[index] = data;
                setBanners(temp)
            }
        }
        if(type === 'delete'){
            let temp = [...banners];
            let index = banners.findIndex(x => x.id === data.id);
            if(index !== -1){
                temp.splice(index, 1);
                setBanners(temp)
            }
        }
    }

    return(
        <div className="menuAdmin">
            <div className='mainDivAdm'>
                Emerald / <span>Banners</span>
            </div>

            <div className="filterMenuAndItems">
                 <div className="filteredMenu">
                     <Dropdown>
                         <Dropdown.Toggle onClick={() => setShow(true)} variant="success" id="dropdown-basic">
                             <span>Create Banner</span>
                             <AddIcon />
                         </Dropdown.Toggle>
                     </Dropdown>
                </div>
            </div>

            {banners &&
                <div className='menuItemsAd'>
                    {banners.length > 0 && banners.map(item => 
                        <div className='singleMenuAd' key={item.id}>
                            <div className='descripsionMenuAd'>
                                <div className='imgADm'>
                                    <img src={item.image_url} alt="" />
                                </div>
                                <div className='topD'>
                                    <span>{item.title}</span>
                                </div>
                                <div className='descripTextAD'> 
                                    Position: {item.position} <br/>
                                    Page Number: {item.page_name} <br/>
                                    Is Active : {item.is_active ? 'true' : 'false'}
                                </div>
                                <div className='btn__group'>
                                    <div className='changeItemAd bg_blue' onClick={() => {setEditBanner(item);setShow(true)}}>Edit</div>
                                    <div className='changeItemAd bg_red' onClick={() => {setEditBanner(item);setShowDelete(true)}}>Delete</div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
            }

            <CreateBanner show={show} setShow={setShow} editItem={editBanner} clearEdit={clearEdit} updateItem={updateState}/>
            <DeleteItem showDelete={showDelete} setShow={setShowDelete} type='banner' item={editBanner} clearEdit={clearEdit} updateC={updateState} />
        </div>
    )
}

export default BannerPage;