import './Home.css';
import ScheduleIcon from '@mui/icons-material/Schedule';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useNavigate } from 'react-router-dom';


function Home() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="home">
        <div className='landH'>
          <div className='right'>
            <div className='bigT'>Time to discover <span>Emerald cafe</span></div>
            <div className='mT'>Uncover the Essence of Emerald: Sip, Savor, and Socialize in Style!</div>
            <div className='buttonsH'>
              <div onClick={() => {navigate('/reservation')}} className='homeBtn'>Reservation</div>
              <div onClick={() => {navigate('/aboutUs')}} className='homeBtn'>Learn More</div>
            </div>
          </div>
        </div>
      </div>
      <div className='secondHomeC'>
        <div className='titleWelcome'>Welcome</div>
        <div className='aboutCaf'>Emerald Bar</div>
        <div className='loremTxt'></div>
        <div className='homeINfo'>
          {/** 
          <div className='singleHomeInf'>
            <RestaurantIcon />
            <span>Lounge Bar</span>
          </div>*/}
          <div className='singleHomeInf'>
            <NightlifeIcon />
            <span>Drinking Bar</span>
          </div>
          {/** 
          <div className='singleHomeInf'>
            <ScheduleIcon />
            <span>24/7</span>
          </div>*/}
          <div className='singleHomeInf'>
            <LocalCafeIcon />
            <span>Coffee Bar</span>
          </div>
          <div className='singleHomeInf'>
            <PeopleAltIcon />
            <span>Friendly</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
