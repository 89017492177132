import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

function DisplayItems({ subT, items }) {
    const controls = useAnimation();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        controls.start('visible');
    }, [subT, controls]);

    const titleVariants = {
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false)
        }, 1000)
        
    }, [])

    return (
        <div className="displayItems">
            <motion.div
                key={subT} 
                className='allItemsM'
                initial='hidden'
                animate={controls}
                variants={titleVariants}
                transition={{ duration: 1 }}
                style={{ overflow: 'hidden' }}
            >
                {loading && <></>}
                {!loading && items &&
                <>
                    {items.filter(x => x.parent?.title?.toLowerCase() === subT.toLowerCase()).length > 0 ? 
                        (items.filter(x => x.parent?.title?.toLowerCase() === subT.toLowerCase()).map((item) => (
                                <div className='singleItemM' key={item.id}>
                                    <div className='itemMImg'>
                                        <img src={item.image_url} alt="" />
                                    </div>
                                    <div className='itemMname'>{item.title}</div>
                                    <div className='itemMdesc'>{item.description}</div>
                                    <div className='itemMdprice'>{item.price} MKD</div>
                                </div>
                            ))
                        ) : (
                            <p>No items found for the selected category.</p>
                        )
                    }
                </>
                }
            </motion.div>
        </div>
    );
}

export default DisplayItems;
