
import { Outlet } from "react-router-dom"
import SideBar from './SideBar/SideBar'

function AdminPanel() {
    
    return (
        <div className="AdminPanel">
            <SideBar/>
            <Outlet />
        </div>
    );
}

export default AdminPanel;
