import './DashboardAdminPanel.css';
import ChartJs from './Chart.jsx';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useEffect, useState } from 'react';
import axiosInstance from '../../redux/axios.js';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        try{
           getData();
        }catch{}
    } , [])

    async function getData(){
        try{
            const response = await axiosInstance.get('admin/dashboard')

            if(response?.data){
                setData(response.data)
            }
        }catch{}
    }

    return (
        <div className="dashboardAd">
            <div className='mainDivAdm'>Emerald / <span>Dashboard</span></div>

            <div className='cardsDash'>
                <div className="singleCard">
                    <div className='topC'>
                        <div className="leftCard">
                            <PendingActionsIcon />
                        </div>
                        <div className='rightCard'>
                            <div className="cardTitle">Pending Reservations</div>
                            <div className="cardV">{data ? data.total_pending_reservations : 0}</div>
                        </div>
                    </div>
                    <div onClick={() => {navigate('/adminpanel/reservation')}} className='bottomC'>
                        <div className='view'>View Reservations</div>
                        <ArrowCircleRightIcon />
                    </div>


                </div>
                <div className="singleCard">
                    <div className='topC'>
                        <div className="leftCard">
                            <RestaurantMenuIcon />
                        </div>
                        <div className='rightCard'>
                            <div className="cardTitle">Menu Items</div>
                            <div className="cardV">{data ? data.total_menu_items : 0}</div>
                        </div>
                    </div>
                    <div onClick={() => {navigate('/adminpanel/menu')}} className='bottomC'>
                        <div className='view'>View Menu</div>
                        <ArrowCircleRightIcon />
                    </div>

                </div>
                <div className="singleCard">
                    <div className='topC'>
                        <div className="leftCard">
                            <InsertInvitationIcon />
                        </div>
                        <div className='rightCard'>
                            <div className="cardTitle">Upcomming Events</div>
                            <div className="cardV">{data?.total_upcoming_events}</div>
                        </div>
                    </div>
                    <div onClick={() => {navigate('/adminpanel/events')}} className='bottomC'>
                        <div className='view'>View Events</div>
                        <ArrowCircleRightIcon />
                    </div>
                </div>
            </div> {/*
            <div className='insideChartt'>
                <div className='chartDiv'>
                   
                    <ChartJs />
                </div>
            </div>*/}

        </div>
    );
}

export default Dashboard;
