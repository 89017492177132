import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   dashboardData: null
};

const AdminSlice = createSlice({
    name: "admin",
    initialState: initialState,
    reducers: {
        setDashboardData: (state, action) => {
            state.dashboardData = action.payload;
        },
        clearAdminSlice: (state) =>{
            state.dashboardData = null;
        }
    }
});

export const {setDashboardData, clearAdminSlice} = AdminSlice.actions;

export default AdminSlice.reducer;