
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import axiosInstance from '../../redux/axios';

function CreateEvent({show, setShow, editItem, clearEdit, updateEvent}){
     
    const handleClose = () =>{
        setShow(false);
    };

    const [eventTitle, setEventTitle] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventPerformers, setEventPerformers] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventPicture, setEventPicture] = useState(null);
    const [eventBanner, setEventBanner] = useState(null);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setErrMsg('')
        setSuccess(false)
    }, [eventTitle, eventDate, eventPerformers, eventDescription, eventPicture, eventBanner])

    useEffect(() => {
        if(editItem){
            setEventTitle(editItem.title)
            setEventDate(editItem.start_date)
            setEventPerformers(editItem.performers)
            setEventDescription(editItem.description)
        }else{
            setEventTitle('')
            setEventDate('')
            setEventPerformers('')
            setEventDescription('')
            setEventBanner(null)
            setEventPicture(null)
        }
        setErrMsg('')
        setSuccess(false)
    }, [editItem])

    const convertDate = (time) => {
        let date = new Date(time);
        var iso = date.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/)

        return iso[1] + ' ' + iso[2];
    }

    const createC = async () => {
        setSuccess(false)
        try{
            if(eventTitle !== '' && eventDate !== '' && eventDescription !== '' && eventPerformers !== '' && eventPicture && eventBanner){
                let data = new FormData();
                
                data.append('title', eventTitle)
                data.append('start_date', convertDate(eventDate))
                data.append('description', eventDescription)
                data.append('performers', eventPerformers)
                data.append('file',  eventPicture)
                data.append('file_banner', eventBanner)

                const response = await axiosInstance.post('admin/events', data)

                if(response.status === 201){
                    if(response?.data?.data){
                        updateEvent(response?.data?.data, 'create')
                    }
                    setErrMsg('')
                    setSuccess(true)
                }
            }else{
                setErrMsg('Fill in the form')
            }
        }catch(err){
            if(!err?.response){
                setErrMsg('No server response. Try again later');
            }else if (err.response?.data?.message){
                setErrMsg(err.response?.data?.message)
            }else{
                setErrMsg('Failed to create item')
            }
        }
    }

    const editC = async () => {
        setSuccess(false)
        try{
            if(eventTitle !== '' && eventDate !== '' && eventDescription !== '' && eventPerformers !== ''){
                let data = new FormData();
                
                data.append('title', eventTitle)
                data.append('start_date', convertDate(eventDate))
                data.append('description', eventDescription)
                data.append('performers', eventPerformers)
                if(eventPicture){
                    data.append('file',  eventPicture)
                }
                if(eventBanner){
                    data.append('file_banner', eventBanner)
                }

                const response = await axiosInstance.post('admin/events/'+editItem.id, data)

                if(response.status === 200){
                    if(response?.data?.data){
                        updateEvent(response?.data?.data, 'edit')
                    }
                    setErrMsg('')
                    setSuccess(true)
                }
            }else{
                setErrMsg('Fill in the form')
            }
        }catch(err){
            if(!err?.response){
                setErrMsg('No server response. Try again later');
            }else if (err.response?.data?.message){
                setErrMsg(err.response?.data?.message)
            }else{
                setErrMsg('Failed to create item')
            }
        }
    }
    return(
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>{editItem ? 'Edit ' + editItem.title : 'Add Event'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3 menu_create_group" size="sm" controlId="exampleForm.ControlInput1">
                        <Form.Label>Title <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={eventTitle} onChange={(e) => setEventTitle(e.target.value)} type="text" placeholder="ex. Week Fest" />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Date <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={eventDate} onChange={(e) => setEventDate(e.target.value)} type="datetime-local" />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Performers <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={eventPerformers} onChange={(e) => setEventPerformers(e.target.value)} type="text" placeholder="ex. Tajzi" />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Description <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control as="textarea" placeholder='...' rows={3}  value={eventDescription} onChange={(e) => setEventDescription(e.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="formFileSm" className="mb-3 menu_create_group">
                        <Form.Label>Picture {!editItem && <span className='errMsg ps-1'>*</span>}</Form.Label>
                        <Form.Control onChange={(e) => setEventPicture(e.target.files[0])} type="file" />
                    </Form.Group>
                    <Form.Group controlId="formFileSm" className="mb-3 menu_create_group">
                        <Form.Label>Banner {!editItem && <span className='errMsg ps-1'>*</span>}</Form.Label>
                        <Form.Control onChange={(e) => setEventBanner(e.target.files[0])} type="file" />
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {editItem ? editC() : createC()}}>
                    {editItem ? 'Update' : 'Add' } Event
                </Button>
            </Modal.Footer>
            {errMsg !== '' && <p className='errMsg center__err'>{errMsg}</p>}
            {success && <p className='succMsg center__err'>Event {editItem ? 'Updated' : 'Created' }</p>}
        </Modal>
    )
}

export default CreateEvent;