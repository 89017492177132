
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navigation from './nav/Navigation';
import Home from './pages/Home';
import Reservation from './pages/Reservation'
import Menu from './pages/Menu/Menu';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from '../src/nav/Footer';
import EventDisplay from './pages/EventDisplay';
import Dashboard from './pages/AdminPanel/Dashboard';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import MenuAdminPanel from './pages/AdminPanel/MenuAdminPanel';
import ReservationAminPanel from './pages/AdminPanel/ReservationAdminPanel';
import EventsAdminPanel from './pages/AdminPanel/EventsAdminPanel.jsx';
import ReservationDisplay from './pages/AdminPanel/ReservationDisplay.jsx';
import Login from './pages/AdminPanel/Login';

import { useSelector } from 'react-redux';
import BannerPage from './pages/AdminPanel/BannerPage.jsx';

function App() {
  const {id} = useSelector(state => state.user);
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={id === -1 ? <Login /> :  <Navigate to='/adminpanel/dashboard'/>} />
          <Route
            path='/*'
            element={
              <div>
                <Navigation />
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='reservation' element={<Reservation />} />
                  <Route path='reservation/:id' element={<EventDisplay />} />
                  <Route path='menu' element={<Menu />} />
                  <Route path='aboutUs' element={<About />} />
                  <Route path='contact' element={<Contact />} />
                </Routes>
                <Footer />
              </div>
            }
          />
            <Route path='adminpanel/*' element={
              id !== -1 ?  
              <AdminPanel />
              :
              <Navigate to='/login'/>
            }>  
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='menu' element={<MenuAdminPanel />} />
            <Route path='menu/:type' element={<MenuAdminPanel />} />
            <Route path='menu/:type/:categoryId' element={<MenuAdminPanel />} />
            <Route path='reservation' element={<ReservationAminPanel />} />
            <Route path='reservation/:id' element={<ReservationDisplay />} />
            <Route path='events' element={<EventsAdminPanel />} />
            <Route path='banners' element={<BannerPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
     
    </div>
  );
}

export default App;
