import './ReservationAdminPanel.css';
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import slika from '../../images/reservations.png'
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axiosInstance from '../../redux/axios';

function ReservationDisplay() {
    const num = 0;
    const { id } = useParams();
    const location = useLocation();
    const selectedItem = location.state.selectedItem;

    const [show, setShow] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [tableNum, setableNum] = useState('');
    const [reservationName, setReservationName] = useState('');
    const [event, setEvent] = useState(null);
    const [tempReservations, setTempReserv] = useState(null)

    const handleClose = () => setShow(false);
    const handleCloseTwo = () => setShowTwo(false);
    const handleShow = (num, reserv) => {
        setShow(true)
        setableNum(num)
        setTempReserv(reserv)
    };
    const handleShow2 = (num, name, reserv) => {
        setShowTwo(true)
        setableNum(num);
        setReservationName(name);
        setTempReserv(reserv)
    };

    useEffect(() => {
        getEvent(id)
    }, [id])
    
    const getEvent = async (id) => {
        try{
            const response = await axiosInstance.get('admin/events/'+id+'?show_reservations=true')

            if(response?.data?.data){
                setEvent(response?.data?.data)
            }
        }catch{}
    }
    const bookReservation = async (id, status) => {
        try{
            const data = new FormData();
            data.append('status', status);
            const result = await axiosInstance.post('admin/reservations/'+id, data);
  
            if(result?.status === 200){
                getEvent(event.id)
                setShow(false)
                setShowTwo(false)
            }
        }catch{} 
    }
    return (
        <div className='displayReservation'>
            <div className="svgI">
                <KeyboardDoubleArrowLeftIcon onClick={() => window.location.pathname = "/adminpanel/reservation"} />
            </div>
            <div className="itemNnn">Item ID: {selectedItem.id}</div>
            <div className='skicaImgR'>
                <div className='explanationR'>
                    <div className='signelExpp'>
                        <div></div>
                        <span>Available tables for reservation</span>
                    </div>
                    <div className='signelExpp'>
                        <div></div>
                        <span>Reserved Tables</span>
                    </div>
                    <div className='signelExpp'>
                        <div></div>
                        <span>Pending Invites for a reservation</span>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className='reservationSkicaR'>
                    <img src={slika} alt="" />
                    {[...Array(55)].map((_, index) => {
                        const tableNumber = num + index + 1;
                        const isTableReserved = (event?.accepted_reservations?.filter(reservation => reservation.table_number.toString() === tableNumber.toString()))?.length > 0;

                        return (
                            <span
                                key={index}
                                onClick={() => (isTableReserved ? handleShow2(tableNumber, event?.accepted_reservations?.filter(reservation => reservation.table_number.toString() === tableNumber.toString()), event?.pending_reservations?.filter(reservation => reservation.table_number.toString() === tableNumber.toString())) : handleShow(tableNumber, event?.pending_reservations?.filter(reservation => reservation.table_number.toString() === tableNumber.toString())))}
                                className={isTableReserved ? index >= 50 ? 'reservedTable boothTable' : 'reservedTable' :  index >= 50 ? 'normalTable boothTable' : 'normalTable'}
                            >
                                {tableNumber}
                                {event?.pending_reservations?.filter(reservation => reservation.table_number.toString() === tableNumber.toString()).length > 0 &&
                                    <div className='relativeDiV'>
                                        <div className='absoluteDiv'>{event?.pending_reservations?.filter(reservation => reservation.table_number.toString() === tableNumber.toString()).length}</div>
                                    </div>
                                }
                            </span>
                        );
                    })}

                </div></div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {(tableNum === 51 || tableNum === 52) ? "Counter " : (tableNum === 53 || tableNum === 54 || tableNum === 55) ? "Booth " : "Table "}Number: {tableNum}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Pending Invites
                    <div className='formsEventR' id='style-2'>
                        {tempReservations && tempReservations.map((val) => {
                            return (
                                <div key={val.id} className='gostiInv'>
                                    <div className='gostiInfo'>
                                        <div>
                                            {val.email}<br/>
                                            <span className='sub_gosti'>Email</span>
                                        </div>
                                        <div>{val.name} <br/>
                                            <span className='sub_gosti'>Name</span>
                                        </div>
                                        <div>{val.phone_number}<br/>
                                            <span className='sub_gosti'>Phone Number</span>
                                        </div>
                                        <div>{val.number_of_guests}<br/>
                                            <span className='sub_gosti'>Number of guests</span>
                                        </div>
                                        <div>{val.status}<br/>
                                            <span className='sub_gosti'>Status</span>
                                        </div>
                                    </div>
                                    <div className='gostiInfoBTN'>
                                        <div className='bookUser' onClick={() => {bookReservation(val.id, 'accepted')}}>Book</div>
                                        <div className='bookUser' onClick={() => {bookReservation(val.id, 'declined')}}>Decline</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showTwo} onHide={handleCloseTwo} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {(tableNum === 51 || tableNum === 52) ? "Counter " : (tableNum === 53 || tableNum === 54 || tableNum === 55) ? "Booth " : "Table "}Number: {tableNum}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='reservedTTT'>
                        {reservationName && 
                            reservationName.map(x => 
                                <span key={x.id}>This table is reserved by : <br/>
                                    {x.name} - {x.email} - {x.phone_number} - {x.number_of_guests}
                                </span>
                            )
                        }
                    </div>
                    Pending Invites
                    <div className='formsEventR' id='style-2'>
                        {tempReservations &&
                        <>
                            {tempReservations.length > 0 && tempReservations.map((val) => 
                                <div key={val.id} className='gostiInv'>
                                    <div className='gostiInfo'>
                                        <div>
                                            {val.email}<br/>
                                            <span className='sub_gosti'>Email</span>
                                        </div>
                                        <div>{val.name} <br/>
                                            <span className='sub_gosti'>Name</span>
                                        </div>
                                        <div>{val.phone_number}<br/>
                                            <span className='sub_gosti'>Phone Number</span>
                                        </div>
                                        <div>{val.number_of_guests}<br/>
                                            <span className='sub_gosti'>Number of guests</span>
                                        </div>
                                        <div>{val.status}<br/>
                                            <span className='sub_gosti'>Status</span>
                                        </div>
                                    </div>
                                    {/*
                                    <div className='bookUser' onClick={() => {bookReservation(val.id, 'accepted')}}>Book</div>*/}
                                </div>
                            )}
                            {tempReservations.length === 0 &&
                                <div style={{textAlign: 'center'}}>No pending reservations</div>
                            }
                        </>
                        }
                        {!tempReservations && 
                            <div style={{textAlign: 'center'}}>No pending reservations</div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={handleCloseTwo}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ReservationDisplay;
