
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Fragment, useEffect, useState } from 'react';
import axiosInstance from '../../redux/axios';

function CreateItem({show, setShow, categories, editItem, clearEdit, updateItem}){
    const handleClose = () =>{
        setShow(false);
    };

    const [itemName, setItemName] = useState('');
    const [itemSlug, setItemSlug] = useState('');
    const [itemPosition, setItemPosition] = useState('');
    const [itemCategory, setItemCategory] = useState(-1);
    const [itemPrice, setItemPrice] = useState('');
    const [itemDesc, setItemDesc] = useState('');
    const [itemPicture, setItemPicture] = useState(null);
    const [isActive, setIsActive] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setErrMsg('')
        setSuccess(false)
    }, [itemName, itemSlug, itemCategory])

    useEffect(() => {
        if(editItem){
            setItemName(editItem.title)
            setItemSlug(editItem.slug)
            setItemPosition(editItem.position)
            setItemPrice(editItem.price)
            setItemDesc(editItem.description)
            setIsActive(editItem.is_active)
            if(editItem.parent){
                setItemCategory(editItem.parent.id)
            }
        }else{
            setItemName('')
            setItemSlug('')
            setItemPosition('')
            setItemPrice('')
            setItemDesc('')
            setIsActive(true)
        }
        setErrMsg('')
        setSuccess(false)
    }, [editItem])

    const editC = async () => {
        setSuccess(false)
        try{
            if(itemName !== '' && itemSlug !== '' && itemPosition !== '' && itemPrice !== '' && itemCategory !== -1 && itemDesc !== ''){
                let data = new FormData();
                
                data.append('title', itemName)
                data.append('slug', itemSlug)
                data.append('position', itemPosition)
                data.append('price', itemPrice)
                data.append('is_active',  isActive ? 1 : 0)
                data.append('description', itemDesc)
                data.append('menu_category_id', itemCategory)
                if(itemPicture){
                    data.append('file', itemPicture)
                }

                const response = await axiosInstance.post('admin/menu-category-items/'+editItem.id, data)

                if(response.status === 200){
                    if(response?.data?.data){
                        updateItem(response?.data?.data, 'edit')
                    }
                    setErrMsg('')
                    setSuccess(true)
                }
            }else{
                setErrMsg('Fill in the form')
        }
        }catch(err){
            if(!err?.response){
                setErrMsg('No server response. Try again later');
            }else if (err.response?.data?.message){
                setErrMsg(err.response?.data?.message)
            }else{
                setErrMsg('Failed to create item')
            }
        } 
    }
    const createC = async () => {
        setSuccess(false)
        try{
            if(itemName !== '' && itemSlug !== '' && itemPosition !== '' && itemPrice !== '' && itemCategory !== -1 && itemDesc !== ''){
                let data = new FormData();
                
                data.append('title', itemName)
                data.append('slug', itemSlug)
                data.append('position', itemPosition)
                data.append('price', itemPrice)
                data.append('is_active',  isActive ? 1 : 0)
                data.append('description', itemDesc)
                data.append('menu_category_id', itemCategory)
                data.append('file', itemPicture)

                const response = await axiosInstance.post('admin/menu-category-items', data)

                if(response.status === 201){
                    if(response?.data?.data){
                        updateItem(response?.data?.data, 'create')
                    }
                    setErrMsg('')
                    setSuccess(true)
                }
            }else{
                setErrMsg('Fill in the form')
        }
        }catch(err){
            if(!err?.response){
                setErrMsg('No server response. Try again later');
            }else if (err.response?.data?.message){
                setErrMsg(err.response?.data?.message)
            }else{
                setErrMsg('Failed to create item')
            }
        }
    }

    return(
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title>{editItem ? 'Edit ' + editItem.title  : 'Add Menu Item'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3 menu_create_group" size="sm" controlId="exampleForm.ControlInput1">
                        <Form.Label>Title <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={itemName} onChange={(e) => setItemName(e.target.value)} type="text" placeholder="ex. Chips" />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Slug <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={itemSlug} onChange={(e) => setItemSlug(e.target.value)} type="text" placeholder="ex. chips" />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Position <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={itemPosition} onChange={(e) => setItemPosition(e.target.value)} type="number" placeholder="ex. 1" min={0} />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Price <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control value={itemPrice} onChange={(e) => setItemPrice(e.target.value)} type="number" placeholder="150 (in MKD)" min={0} />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Category <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Select value={itemCategory} onChange={(e) => {setItemCategory(e.target.value)}} aria-label="Default select example">
                            <option value={-1}></option>
                            {categories &&
                                categories.filter(x => !x.parent).map((category) => (
                                    <Fragment key={category.slug}>
                                        <option
                                            value={category.id}
                                        >
                                            {category.title}
                                        </option>
                                        {category.sub_categories && category.sub_categories.map((sub,index) => 
                                            <option
                                                key={sub.slug}
                                                value={sub.id}
                                            >
                                                {sub.title}
                                            </option>
                                        )}
                                    </Fragment>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlTextarea1">
                        <Form.Check // prettier-ignore
                            type="switch"
                            checked={isActive}
                            onChange={(e) => {setIsActive(e.target.checked)}}
                            id="active_switch"
                            label="Is Active"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3 menu_create_group" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Item Description <span className='errMsg ps-1'>*</span></Form.Label>
                        <Form.Control as="textarea" value={itemDesc} onChange={(e) => setItemDesc(e.target.value)} placeholder='This is the best chips in town!' rows={3} />
                    </Form.Group>
                    <Form.Group controlId="formFileSm" className="mb-3 menu_create_group">
                        <Form.Label>Item Picture {!editItem &&<span className='errMsg ps-1'>*</span>}</Form.Label>
                        <Form.Control onChange={(e) => setItemPicture(e.target.files[0])} type="file" accept="image/*" />
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => editItem ? editC() : createC()}>
                    {editItem ? ' Edit' : ' Create'}
                </Button>
                
            </Modal.Footer>
            {errMsg !== '' && <p className='errMsg center__err'>{errMsg}</p>}
            {success && <p className='succMsg center__err'>Item {!editItem ? ' Created' : ' Edited'}</p>}
        </Modal>
    )
}

export default CreateItem;