import Carousel from 'react-bootstrap/Carousel';
import fimage1 from '../../images/CarouselImages/fimage.jpg'
import fimage3 from '../../images/CarouselImages/image3.jpg'
import line from '../../images/CarouselImages/separator.fc5405c5995258e079d7072ed26a6975.svg';
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../images/logo1.png'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { motion, AnimatePresence } from "framer-motion";
import { Data } from './MenuSubsData';
import  DisplayItems from './DisplayItems';
import './Menu.css';
import axiosInstance from '../../redux/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setBanners } from '../../redux/reducers/menuReducer'

function Menu() {
    const { banners } = useSelector(state => state.menu);
    const dispatch = useDispatch();

    const [subT, setSubT] = useState('Coffee');
    const [show, setShow] = useState(false);
    const [dropdownStates, setDropdownStates] = useState(
        Data.map(() => ({ isOpen: false }))
    );

    const [categories, setCategories] = useState(null);
    const [items, setItems] = useState(null);

    useEffect(() => {
        if(!banners){
            getBanners();
        }
        getCategories();
        getItems();
    }, [])

    const getBanners = async () => {
        try{
            const response = await axiosInstance.get('banners');
            if(response?.data?.data){
                dispatch(setBanners(response.data.data));
            }
        }catch{}
    }

    const getCategories = async () => {
        try{
            const response = await axiosInstance.get('menu-categories');
            if(response.data.data){
                setCategories(response.data.data)
            }
        }catch{}
    }

    const getItems = async () => {
        try{
            const response = await axiosInstance.get('menu-category-items');
            if(response.data.data){
                setItems(response.data.data)
            }
        }catch{}
    }
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const toggleDropdown = (index) => {
        setDropdownStates((prevStates) =>
            prevStates.map((state, i) => (i === index ? { ...state, isOpen: !state.isOpen } : state))
        );
    };

    const changeMenu = (newValue) => {
        setShow(false)
        setSubT(newValue);
    };


    return (
        <div className='Menu'>
            {/** 
            <div className='slider'>
                    {!banners &&
                        <Carousel>
                            <Carousel.Item>
                                <img className='ImgS' src={fimage1} alt="1" />
                                <Carousel.Caption>
                                    <h2>Best Dessert in Town</h2>
                                    <img className='lineMenu' src={line} alt="line" />
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className='ImgS' src={fimage3} alt="3" />
                                <Carousel.Caption>
                                    <h2>Best Drinks in Town</h2>
                                    <img className='lineMenu' src={line} alt="line" />
                                    <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    }
                    {banners && 
                        <Carousel>
                        {banners.map(b => 
                            <Carousel.Item key={b.id}>
                                <img className='ImgS' src={b.image_url} alt="1" />
                                <Carousel.Caption>
                                    <h2>{b.title}</h2>
                                    <img className='lineMenu' src={line} alt="line" />
                                    <p>{b.description === '.' ? '' : b.description}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )}
                        </Carousel>
                    }
            </div>*/}
            <div className='lineAf'></div>
            <div className='menuItems'>
                <div className='titleMenu'>Menu</div>
                <div className='subTitleM'>
                    {subT}
                    <label htmlFor='check' className='check_menu_sub' onClick={handleShow}>
                        <input type='checkbox' id='check' />
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                </div>
                <img className='lineMenuA' src={line} alt='line' />
                <DisplayItems subT={subT} items={items} />

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='menuC'>
                            <div className='imgMenu'>
                                <img src={logo} className='canMI' alt='' />
                            </div>
                            <Navbar expand='lg' className='bg-body-tertiary'>
                                <Container>
                                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                                        <Nav className='me-auto'>
                                            {categories && categories.map((item, index) =>
                                                item.sub_categories ? (
                                                    <NavDropdown
                                                        key={item.id}
                                                        title={item.title}
                                                        id={`basic-nav-dropdown-${item.id}`}
                                                        onClick={() => toggleDropdown(index)}
                                                        show={dropdownStates[index].isOpen}
                                                    >
                                                        <AnimatePresence>
                                                            {dropdownStates[index].isOpen && (
                                                                <motion.div
                                                                    initial={{ opacity: 0, height: 0 }}
                                                                    animate={{ opacity: 1, height: 'auto' }}
                                                                    exit={{ opacity: 0, height: 0 }}
                                                                    transition={{ duration: 0.3 }}
                                                                    style={{ overflow: 'hidden' }}
                                                                >
                                                                    {/* Dropdown items here */}
                                                                    {item.sub_categories.map((sub) => (
                                                                        <NavDropdown.Item
                                                                            key={sub.id}
                                                                            onClick={() => changeMenu(sub.title)}
                                                                        >
                                                                            {sub.title}
                                                                        </NavDropdown.Item>
                                                                    ))}
                                                                </motion.div>
                                                            )}
                                                        </AnimatePresence>
                                                    </NavDropdown>
                                                ) : (
                                                    <Nav.Link key={item.id} onClick={() => changeMenu(item.title)}>
                                                        {item.title}
                                                    </Nav.Link>
                                                )
                                            )}
                                        </Nav>
                                </Container>
                            </Navbar>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>

        </div>

    );
}

export default Menu;
