export const Data = [
    {
        id: 0,
        title: 'Special Offers',
        hasSubs:false,
        open: false,
    },
    {
        id: 1,
        title: 'Jadejne',
        hasSubs:false,
        open: false,
    },
    {
        id: 2,
        title: 'Drinks',
        hasSubs:true,
        open: false,
        subs: [{
            id: 0,
            subName: 'Coffe',
        },
        {
            id: 1,
            subName: 'Non Alcohol Drinks',
        },
        {
            id: 2,
            subName: 'Alcohol Drinks',
        },
        {
            id: 3,
            subName: 'Coctails',
        },
        ]
    },
    {
        id: 3,
        title: 'Desserts',
        hasSubs:false,
        open: false,
    },
]