import './Reservation.css';
import { motion } from 'framer-motion';
import { useParams } from "react-router-dom";
import table from '../images/tableW.jpg';
import slika from '../images/reservations.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import pajak from '../images/pajak.jpeg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import { useState } from 'react';
import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import axiosInstance from '../redux/axios';

function EventDisplay() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number_of_guests, setNOG] = useState('');
    const [phone_number, setPhone] = useState('');
    const [err, setErr] = useState('');

    const num = 0;
    const { id } = useParams();
    const titleVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0 },
    };
    const [show, setShow] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [tableNum, setableNum] = useState('');

    const [event, setEvent] = useState(null);

    const handleClose = () => setShow(false);
    const handleCloseTwo = () => setShowTwo(false);
    const handleShow = (num) => {
        setShow(true)
        setableNum(num)
        setErr('')
    };
    const convertDate = (date) => {
        try{
            let spl = date.split('T')
            let time = spl[1]?.split(':')
            return spl[0] + ' ' + (parseInt(time[0])+1) + ':' +time[1];
        }
        catch{}
    }    
    const handleShow2 = (num, name) => {
        setShowTwo(true)
        setableNum(num);
        setErr('')
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getEvent(id)
    }, [id])

    const getEvent = async (id) => {
        try{
            const response = await axiosInstance.get('events/'+id+'?show_reservations=true');
            if(response?.data?.data){
                setEvent(response?.data?.data)
            }
        }catch{}
    }

    const makeReservation = async () => {
        try{
            if(name !== '' && email !== '' && phone_number !== '' && number_of_guests !== ''){
                const data = new FormData();
                data.append('name', name)
                data.append('email', email)
                data.append('number_of_guests', number_of_guests)
                data.append('phone_number', phone_number)
                data.append('event_id', id)
                data.append('table_number', tableNum)
                const response = await axiosInstance.post('reservations', data);
                if(response.status === 201){
                    setErr('Table Queued. Check again if your reservation has been approved')
                }
            }else{
                setErr('Fill in the form')
            }
        }catch(err){
            if(!err?.response){
                setErr('No server response. Try again later');
            }else if (err.response?.data?.message){
                setErr(err.response?.data?.message)
            }else{
                setErr('Failed to make reservation')
            }
        }
    }
    return (
        <div className="displayEvent">
            <div className='reservationCE'>
                <div className='arrowBack'>
                    <KeyboardDoubleArrowLeftIcon onClick={() => window.location.pathname = "reservation"} />
                </div>
                <div className='imgR'>
                    <img src={event?.image_banner_url} alt="" />
                </div>
                <motion.div
                    className='titleReservation'
                    initial='hidden'
                    animate='visible'
                    variants={titleVariants}
                    transition={{ duration: 1 }}
                    style={{ overflow: 'hidden' }}
                >
                    <span>{event ? event.title : ''}</span>
                    <div className='dateEvent'>
                        <EventIcon />
                        <span>{event ? convertDate(event.start_date) : ''}</span>
                    </div>
                </motion.div>
            </div>
                <div className='tableReservations'>
                    <div className='leftSk'>
                        <div className='reserveW'>RESERVE A TABLE</div>
                        <div className='reserveWtext'>{event? event.description : ''}</div>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='reservationSkicaR'>
                            <img src={slika} alt="" />
                            {[...Array(55)].map((_, index) => {
                                const tableNumber = num + index + 1;
                                const isTableReserved = (event?.accepted_reservations?.filter(reservation => reservation.table_number.toString() === tableNumber.toString()))?.length > 0;

                                return (
                                    <span
                                    key={index}
                                    onClick={() => (isTableReserved ? handleShow2(tableNumber) : handleShow(tableNumber))}
                                    className={isTableReserved ? index >= 50 ? 'reservedTable boothTable' : 'reservedTable' :  index >= 50 ? 'normalTable boothTable' : 'normalTable'}
                                >
                                   {index >= 50 ? "" : ""} {tableNumber}
                                </span>
                                );
                            })}

                        </div>
                    </div>

                    
                    <div className='explanationR pt-4'>
                            <div className='signelExpp'>
                                <div></div>
                                <span>Available tables for reservation</span>
                            </div>
                            <div className='signelExpp'>
                                <div></div>
                                <span>Reserved Tables</span>
                            </div>
                        </div>
                </div>
                <div className='eventInfo'>
                    <div className='eventInfoC'>
                        <div className='eventInfoImg'>
                            <img src={event?.image_url} alt="" />
                        </div>
                        <div className='infoAbEvent'>
                            <div className='singleINfoE'>
                                <span>SOCIAL MEDIA </span>
                                <div className='socialMediaE'>
                                    <InstagramIcon onClick={() => {window.open('https://www.instagram.com/emeraldbgd', '_blank');}}/>
                                    {/* 
                                    <FacebookIcon />
                                    <XIcon />*/}
                                </div>
                            </div>
                            {/*
                            <div className='singleINfoE'>
                                <span>DATE AND TIME</span>
                                <span>Friday Feb 16 11:30am <AccessTimeIcon /></span>
                            </div>*/}
                            <div className='singleINfoE'>
                                <span>GET DIRECTIONS</span>
                                <span className='adressEvent'>Bogdanci, Brand Petrusev broj 36 <LocationOnIcon /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {(tableNum === 51 || tableNum === 52) ? "Counter " : (tableNum === 53 || tableNum === 54 || tableNum === 55) ? "Booth " : "Table "} Number: {tableNum} 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Enter your info for the reservation so we can call you back to approve the reservation!
                    <div className='formsEvent'>
                        <Form>
                            <Form.Group className="mb-3 menu_create_group" controlId="formGroupEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control size="sm" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group className="mb-3 menu_create_group" controlId="formGroupName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control size="sm" value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter name" />
                            </Form.Group>
                            <Form.Group className="mb-3 menu_create_group" controlId="formGroupName">
                                <Form.Label>Number of Guest</Form.Label>
                                <Form.Control size="sm" type="number" value={number_of_guests} onChange={(e) => setNOG(e.target.value)} placeholder="Guest Number" min={0} />
                            </Form.Group>
                            <Form.Group className="mb-3 menu_create_group" controlId="formGroupPhoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control size="sm" value={phone_number} onChange={(e) => setPhone(e.target.value)} type="text" placeholder="+389 70944445" />
                            </Form.Group>
                        </Form>
                    </div>
                    
                    <span className={err === 'Table Queued. Check again if your reservation has been approved' ? 'text-green' : 'text-red'}>{err}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={() => makeReservation()}>
                        Reserve
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showTwo} onHide={handleCloseTwo} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {(tableNum === 51 || tableNum === 52) ? "Counter " : (tableNum === 53 || tableNum === 54 || tableNum === 55) ? "Booth " : "Table "}Number: {tableNum}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This table is already reserved.
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default EventDisplay;
