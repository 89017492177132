import './Reservation.css'
import { motion } from 'framer-motion';
import ResImg from '../images/mja.jpeg.webp'
import pajak from '../images/pajak.jpeg'
import { Events } from './Events';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import axiosInstance from '../redux/axios';

function Reservation() {
  const navigate = useNavigate();
  const [events, setEvents] = useState(null);

  useEffect(() => {
    getEvents();
  }, [])

  const convertDate = (date) => {
    try{
        let spl = date.split('T')
        let time = spl[1]?.split(':')
        return spl[0] + ' ' + (parseInt(time[0])+1) + ':' +time[1];
    }
    catch{}
  }

  const getEvents = async () => {
    try{
      const response = await axiosInstance.get('/events?order_by_start_date=desc')
      if(response?.data?.data){
        setEvents(response?.data?.data)
      }
    }catch{}
  }
  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };
  const titleVariants2 = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <div className="Reservation">
      <div className='reservationC'>
        <div className='imgR'>
          <img src={ResImg} alt="" />
        </div>
        <motion.div
          className='titleReservation'
          initial='hidden'
          animate='visible'
          variants={titleVariants}
          transition={{ duration: 1 }}
          style={{ overflow: 'hidden' }}
        >
          Reservation
        </motion.div>
      </div>
      <div className='EventsContent'>
        <div className='titleReservationPP'>Choose Event</div>
        <motion.div
          className='insideEvents'
          initial='hidden'
          animate='visible'
          variants={titleVariants2}
          transition={{ duration: 1 }}
          style={{ overflow: 'hidden' }}
        >
          {events && events.map((val) => {
            return (
              <div className='singleEvent' key={val.id}>
                <div className='imageEvent'>
                  <img src={val.image_url} alt="" />
                  <div className='absoluteEvent'>
                    <div className='insideAbE'>
                      <div className='bookReserve' onClick={() => { navigate(`/reservation/${val.id}`) }}>Reserve Table</div>
                    </div>
                  </div>
                </div>
                <div className='eventArtist'>
                  <span style={{width: '100%'}} className='pt-2'>{val.title} - {val.performers}</span>
                  <span style={{textAlign: 'center'}}>{convertDate(val.start_date)}</span>
                </div>
              </div>
            )
          })}
        </motion.div>
        <div className='insideEvents'>


        </div>
      </div>
    </div>
  );
}

export default Reservation;
