
import './EventsAdminPanel.css';
import Dropdown from 'react-bootstrap/Dropdown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddIcon from '@mui/icons-material/Add';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import slika from '../../images/pajak.jpeg'
import React, { useEffect, useState } from 'react';
import { Events } from '../Events';
import {useNavigate } from 'react-router-dom';
import CreateEvent from '../Menu/CreateEvent';
import axiosInstance from '../../redux/axios';
import DeleteItem from '../Menu/DeleteItem';

function EventsAdminPanel() {
    const [events, setEvents] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState("All");
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false)

    const [editItem, setEditItem] = useState(null);

    useEffect(() => {
        getEvents()
    }, [])

    useEffect(() => {
        if(selectedCategory === 'Latest Upcomming')
        {
            getEvents('desc')
        }
        else if(selectedCategory ===  'Earliest Upcomming'){
            getEvents('asc') 
        }
        else{
            getEvents();
        }
    }, [selectedCategory])

    const getEvents = async (type) => {
        try{
            let str = ''
            if(type){
                str = '?order_by_start_date='+type
            }
            const response = await axiosInstance.get('events'+str);

            if(response?.data?.data){
                setEvents(response?.data?.data)
            }
        }catch{}
    }
    const clearEdit = () => {
        setEditItem(null)
    }

    const handleShow = () => setShow(true);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };
    const convertDate = (date) => {
        try{
            let spl = date.split('T')
            let time = spl[1]?.split(':')
            return spl[0] + ' ' + (parseInt(time[0])+1) + ':' +time[1];
        }
        catch{}
    }
    const handleEditItemClick = (item) => {
        setEditItem(item);
        setShow(true)
        //navigate(`/adminpanel/events/${item.id}`, { state: { selectedItem: item } });
    };
    const deleteEvent = (item) => {
        setEditItem(item)
        setShowDelete(true)
    }

    const updateEvent = async (newEvent, type) => {
        try{
            if(type === 'edit'){
                let temp = [...events];
                let index = events.findIndex(x => x.id === newEvent.id);
                if(index !== -1){
                    temp[index] = newEvent;
                    setEvents(temp)
                }
               
            }
            if(type === 'create'){
                let temp = [...events];
                temp.push(newEvent)
                setEvents(temp)
            }
            if(type === 'delete'){
                let temp = [...events];
                let index = events.findIndex(x => x.id === newEvent.id);
                if(index !== -1){
                    temp.splice(index, 1);
                    setEvents(temp)
                }
            }
        }catch{}
    }

    return (
        <div className="eventsAdm">
            <div className='mainDivAdm'>Emerald / <span>Events</span></div>
            <div className="filterMenuAndItems">
                <div className="filteredMenu">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <span>{selectedCategory}</span>
                            <FilterAltIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleCategorySelect("All")}>All</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleCategorySelect("Latest Upcomming")}>Latest Upcomming</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleCategorySelect("Earliest Upcomming")}>Earliest Upcomming</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={handleShow}>
                            <span>Create</span>
                            <AddIcon />
                        </Dropdown.Toggle>
                    </Dropdown>
                </div>
                <div className='menuItemsAd'>
                    {events && events.map((val) => {
                        return (
                            <div className='singleMenuAd' key={val.id}>
                                <div className='imgADm'>
                                    <img src={val.image_url} alt="" />
                                </div>
                                <div className='infoAe'>
                                    <span>{val.title}</span>
                                    <span>{val.performers}</span>
                                    <span>{convertDate(val.start_date)}</span>
                                    <div className='btn__group'>
                                        <div className='changeItemAd' onClick={() => handleEditItemClick(val)}>Edit Event</div>
                                        <div className='changeItemAd bg_red' onClick={() => deleteEvent(val)}>Delete Event</div>
                                    </div>
                                </div>
                               
                            </div>
                        )
                    })}
                </div>
            </div>
            {/*ADD, EDIT EVENT ITEM */}
            <CreateEvent show={show} setShow={setShow} editItem={editItem} clearEdit={clearEdit} updateEvent={updateEvent} />
            {/*DELETE EVENT ITEM */}
            <DeleteItem showDelete={showDelete} setShow={setShowDelete} item={editItem} type='event' clearEdit={clearEdit} updateC={updateEvent} />
        </div>
    );
}

export default EventsAdminPanel;
