import './ReservationAdminPanel.css';
import Dropdown from 'react-bootstrap/Dropdown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import axiosInstance from '../../redux/axios';

function ReservationAminPanel() {
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [data, setData] = useState(null);
    const [events, setEvents] = useState(false);

    const convertDate = (date) => {
        try{
            let spl = date.split('T')
            let time = spl[1]?.split(':')
            return spl[0] + ' ' + (parseInt(time[0])+1) + ':' +time[1];
        }
        catch{}
    } 

    useEffect(() => {
        try{
           getData();
           getEvents();
        }catch{}
    } , [])

    async function getData(){
        try{
            const response = await axiosInstance.get('admin/dashboard')

            if(response?.data){
                setData(response.data)
            }
        }catch{}
    }

    const getEvents = async (type) => {
        try{
            let str = ''
            if(type){
                str = '&order_by_start_date='+type
            }
            const response = await axiosInstance.get('events?show_reservations=true'+str);

            if(response?.data?.data){
                setEvents(response?.data?.data)
            }
        }catch{}
    }
    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };
    const handleEditItemClick = (item) => {
        navigate(`/adminpanel/reservation/${item.id}`, { state: { selectedItem: item } });
    };

    return (
        <div className="reservationAD">
            <div className='mainDivAdm'>Emerald / <span>Reservation</span></div>
            <div className="filterMenuAndItems">
                <div className="filteredMenu">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <span>{selectedCategory}</span>
                            <FilterAltIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleCategorySelect("All")}>All</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleCategorySelect("Тhe very first")}>Тhe very first</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleCategorySelect("Most Recent")}>Most Recent</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='menuItemsAdR'>
                    <div className='pendings'>Pending Reservation: <span className='pendingUserss'>{data ? data.total_pending_reservations : 0}</span></div>
                    <div className='insidePend'>
                        {events && events.map((val) => {
                            return (
                                <div className='singleMenuAd' key={val.id}>
                                    {val.pending_reservations?.length > 0 && (
                                        <div className='absPending'>
                                            {val.pending_reservations?.length}
                                        </div>
                                    )}
                                    <div className='imgADm'>
                                        <img src={val.image_url} alt="" />
                                    </div>
                                    <div className='infoAe'>
                                        <span>{val.title}</span>
                                        <span>{convertDate(val.start_date)}</span>
                                        <div className='changeItemAd' onClick={() => handleEditItemClick(val)}>Go to Reservations</div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReservationAminPanel;
