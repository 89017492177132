
import './About.css'
import slika from '../images/skica.jpg';
import { motion } from 'framer-motion';
import sllika from '../images/CarouselImages/image2.jpg'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


function About() {
  const navigate = useNavigate();
  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };
  const titleVariants2 = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };
  const titleVariants3 = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };
  return (
    <div className="about">
      <div className='aboutUsS'>
        <motion.div
          className='titleAbout'
          initial='hidden'
          animate='visible'
          variants={titleVariants}
          transition={{ duration: 1 }}
          style={{ overflow: 'hidden' }}
        >
          About Emerald Café
        </motion.div>
      </div>
      <div className='lineAf'></div>
      <div className='aboutContent pb-5'>
        <div className='firstCabout'>
          <div className='titleF'>Newest Gem</div>
          <div className='textF'>Welcome to Bogdanci's first luxury bar! Nestled in the heart of this charming Macedonian town, our bar promises an experience like no other.
          Our expert mixologists are here to craft the perfect drink just for you. From classic cocktails to innovative creations made with the finest ingredients, every sip is a delight for your senses.
          But it's not just about the drinks – it's about the experience. Whether you're enjoying a quiet moment in our cozy lounge or taking in the breathtaking views from our outdoor terrace, every moment here is one to cherish.
          </div>
        </div>

        <motion.div
          className='secondCabout'
          initial='hidden'
          animate='visible'
          variants={titleVariants2}
          transition={{ duration: 1 }}
          style={{ overflow: 'hidden' }}
        >
          <div className='aboutImgOne'>
            <div className='imgAbImg'>
              <img src={slika} alt="" />
            </div>
            <div className='aboutImgText'>
              Reserve your table effortlessly at Bogdanci's luxury bar. Just click on your preferred table number online, provide basic details, and get ready for an unforgettable experience in elegance and indulgence. Your seat is just a click away!
            </div>
            <Button onClick={() => {navigate('/reservation')}}>Reserve Now</Button>
          </div>
          <div className='aboutImgTwo'>
            <img src={sllika} alt="" />
          </div>
        </motion.div>

        {/*
        <motion.div
          className='thirdCabout'
          initial='hidden'
          animate='visible'
          variants={titleVariants3}
          transition={{ duration: 1 }}
          style={{ overflow: 'hidden' }}
        >
          <div className='aboutImgOneT'>
            <div className='imgAbImg'>
              <img src={slika} alt="" />
            </div>
            <div className='aboutImgText'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil nesciunt dolore qui nisi, iusto dolorem eum quis. Quasi
              veniam repudiandae iure laboriosam officia sint voluptatibus vel tempore dolores. Quibusdam, eius.</div>
          </div>
          <div className='aboutImgTwo'>
            <img src={sllika} alt="" />
          </div>
        </motion.div>
        */}
      </div>
    </div>
  );
}

export default About;
