import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './DashboardAdminPanel.css' //samo za fontot na data na chartut

const data = [
  {
    name: '01.01.2023',
    Reservations: 4000,
    parts: 2400,
    visitors: 2400,
    profit:5000,
  },
  {
    name: '02.01.2023',
    Reservations: 3000,
    parts: 1398,
    visitors: 2400,
    profit: 2210,
  },
  {
    name: '03.01.2023',
    Reservations: 2000,
    parts: 9800,
    visitors: 2400,
    profit: 2290,
  },
  {
    name: '04.01.2023',
    Reservations: 2780,
    parts: 3908,
    visitors: 2400,
    profit: 2000,
  },
  {
    name: '05.01.2023',
    Reservations: 1890,
    parts: 4800,
    visitors: 2400,
    profit: 2181,
  },
  {
    name: '06.01.2023',
    Reservations: 1890,
    parts: 4800,
    visitors: 2400,
    profit: 2181,
  },
  {
    name: '07.01.2023',
    Reservations: 1890,
    parts: 3569,
    visitors: 2100,
    profit: 5000,
  },
];


export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="Reservations" stroke="#e0c659" fill="#e0c659" fillOpacity={0.1} />
          {/*
          <Area type="monotone" dataKey="parts" stroke="#63b761" fill="#63b761" fillOpacity={0.1}  />
          <Area type="monotone" dataKey="visitors" stroke="#eeac56" fill="#eeac56" fillOpacity={0.1} />
          <Area type="monotone" dataKey="profit" stroke="#d65351" fill="#d65351" fillOpacity={0.3} activeDot={{ r: 8 }} />
          */}
          
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}