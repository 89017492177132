
import './Footer.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';


const pagesFooter = [
  { id: 1, name: 'Home', link: '/' },
  { id: 2, name: 'Menu', link: '/menu' },
  { id: 3, name: 'Reservation', link: '/reservation' },
  { id: 4, name: 'Contact', link: '/contact' },
  { id: 5, name: 'About', link: '/aboutUs' },
];


function Footer() {
  return (
    <div className="footer">
      <div className='insideFooter'>
        <div className='threeBot'>
          <div className='leftFooter'>
            <div className='titleFooterE'>Emerald Bar</div>
            <span >Welcome to Bogdanci's first luxury bar! Nestled in the heart of this charming Macedonian town, our bar promises an experience like no other.</span>
            <div onClick={() =>  window.location.pathname = '/aboutUs'} className='readMoreF'>Read More <ArrowRightAltIcon /></div>
          </div>
          <div className='middleFooter'>
            <div className='titleFooterE pt-4'></div>
            <div className='navsFooter'>
              {pagesFooter.map((val, key) => {
                return (
                  <div className='singleFooterNav' key={key} onClick={() => window.location.pathname = val.link} >
                    {val.name}
                  </div>
                )
              })}
            </div>
          </div>
          <div className='rightFooter'>
            <div className='titleFooterE'>Social Media</div>
            <div className='iconsFooter'>
              <InstagramIcon onClick={() => {window.open('https://www.instagram.com/emeraldbgd', '_blank');}} />
              {/*
              <FacebookIcon />
            <XIcon />*/}
            </div>
          </div>
        </div>
        <div className='copyRightContent'>
          <span>Copyright ©2024 All rights reserved | Made by MTech</span>
        </div>

      </div>
    </div>
  );
}

export default Footer;
