import { useEffect, useState } from "react";
import './Login.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import React from 'react';
import logo from '../../images/logo1.png';

import axiosInstance from '../../redux/axios';
import { setToken, setUser } from '../../redux/reducers/userReducer';
import { useDispatch } from 'react-redux';

import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";

function Login(){
    const [email, setEmail] = useState('');
    const [password, setPass] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setErrMsg('');
     }, [email, password])

     const hangleSubmit = async (e) => {
        e.preventDefault();

        try{
            const formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);

            const response = await axiosInstance.post('auth/login', formData,{
                headers: {'Content-Type' : 'application/json'}
            })
            if(response.data?.data){
                dispatch(setToken(response.data.data?.token));
            }
            
            const response1 = await axiosInstance.get('auth/me');
            
            if(response1.data?.data){
                dispatch(setUser(response1.data.data));
                navigate('/adminpanel/dashboard')
            }
                
        }catch(err){
            if(!err?.response){
                setErrMsg('No server response. Try again later');
            }else if (err.response?.status === 401){
                setErrMsg('Email & Password does not match.')
            }else{
                setErrMsg('Log in failed')
            }
        }
    }

    return(
        <div className="login_cont">
            <MDBContainer className="my-5 first_row">
                <MDBRow>

                    <MDBCol col='6' className="mb-5 first_whi">
                        <div className="d-flex flex-column ms-5 me-5">

                            <div className="text-center pt-5">
                                <img src={logo}
                                    style={{width: '200px'}} alt="logo" />
                                <p className="mt-1 mb-5 pb-1">Admin Panel</p>
                            </div>

                            <MDBInput value={email} onChange={(e) => setEmail(e.target.value)} wrapperClass='mb-4 logininput' label='Email address' id='form1' type='email'/>
                            <MDBInput value={password} onChange={(e) => setPass(e.target.value)} wrapperClass='mb-4 logininput' label='Password' id='form2' type='password'/>


                            <div className="text-center pt-1 mb-5 pb-1">
                            <MDBBtn onClick={hangleSubmit} className="mb-4 w-100 gradient-custom-2">Sign in</MDBBtn>
                            {errMsg !== '' && <p className="errMsg">{errMsg}</p>}
                            </div>

                        </div>

                    </MDBCol>

                </MDBRow>
            </MDBContainer>
        </div>
    )
}

export default Login;