import './MenuAdminPanel.css';
import Dropdown from 'react-bootstrap/Dropdown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../redux/axios';
import CreateCategory from '../Menu/CreateCategory';
import CreateItem from '../Menu/CreateItem';
import DeleteItem from '../Menu/DeleteItem';

function MenuAdminPanel() {
    const params = useParams();
    const navigate = useNavigate();

    const [selectedCategory, setSelectedCategory] = useState("All");

    const [categories, setCategories] = useState(null);
    const [items, setItems] = useState(null);

    const [category, setCategory] = useState(null);
    const [editCategory, setEditCategory] = useState(null);
    const [editItem, setEditItem] = useState(null);

    const clearEdit = () => {
        setEditCategory(null);
        setEditItem(null)
    }
    
    let description = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet eveniet quam perspiciatis eos accusamus. Ex facere labore cum facilis quidem soluta? Eligendi corporis voluptatibus soluta officiis accusamus impedit veritatis explicabo.'
    const MAX_DESCRIPTION_LENGTH = 100;

    const truncatedDescription = description.length > MAX_DESCRIPTION_LENGTH
        ? `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
        : description;

    const [show, setShow] = useState(false);
    const [showCategory, setShowCategory] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        getCategories();
        getItems();
    }, [])

    useEffect(() => {
        setCategory(null)
        if(params?.categoryId){
            getCategory(params?.categoryId)
        }
    },[params?.categoryId])

    const getCategories = async () => {
        try{
            const response = await axiosInstance.get('admin/menu-categories');
            if(response?.data?.data){
                setCategories(response?.data?.data)
            }
        }catch{}
    }
    
    const getItems = async () => {
        try{
            let response = await axiosInstance.get('admin/menu-category-items');
            if(response?.data?.data){
                setItems(response?.data?.data)
            }

        }catch{}
    }

    const getCategory = async (id) => {
        try{
            let response = await axiosInstance.get(`admin/menu-categories/${id}`);
            if(response?.data?.data){
                setCategory(response?.data?.data)
            }
           
        }catch{}
    }

    const updateCategory = async (newCategory, parentID, type) => {
        try{
            if(type === 'edit'){
                if(parseInt(parentID) === -1){
                    let index = categories.findIndex(x => x.id === newCategory.id);
                    if(index !== -1){
                        let temp = [...categories];
                        temp[index] = newCategory;
                        setCategories(temp)
                    }
                }else{
                    let index = categories.findIndex(x => x.id === parseInt(parentID));
                    if(index !== -1){
                    let subI = categories[index]?.sub_categories?.findIndex(x => x.id === newCategory.id)
                    
                    if(subI !== -1){
                            getCategory(parentID)
                    }
                    }
                }
            }
            if(type === 'create' || type === 'delete'){
                if(parseInt(parentID) === -1){
                    getCategories()
                }else{
                    let index = categories.findIndex(x => x.id === parseInt(parentID));
                    if(index !== -1){
                        getCategory(parentID);
                    }
                }
            }
        }catch{}
    }

    const updateItem = async (newItem, type) => {
        try{
            if(type === 'edit'){
                let temp = [...items];
                let index = items.findIndex(x => x.id === newItem.id);
                if(index !== -1){
                    temp[index] = newItem;
                    setItems(temp)
                }
               
            }
            if(type === 'create'){
                let temp = [...items];
                temp.push(newItem)
                setItems(temp)
            }
            if(type === 'delete'){
                let temp = [...items];
                let index = items.findIndex(x => x.id === newItem.id);
                if(index !== -1){
                    temp.splice(index, 1);
                    setItems(temp)
                }
            }
        }catch{}
    }

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    return (
        <div className="menuAdmin">
            <div className='mainDivAdm'>
                Emerald / 
                <span onClick={() => { if(params?.type){navigate(`/adminpanel/menu`)}}} className={params?.type ? 'clickable_m' : ''}> Menu</span>
                {params?.type ?  
                <span> / <span onClick={() => { if(category){navigate(`/adminpanel/menu/${params?.type}`)}}} className={category ? 'clickable_m' : ''}>{ params?.type}</span></span>
                : ''}
                {category ? ' / ' + category?.title : ''}
            </div>
            <div className="filterMenuAndItems">
                 
                <div className="filteredMenu">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => setShowCategory(true)}>
                            <span>Create Category</span>
                            <AddIcon />
                        </Dropdown.Toggle>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => setShow(true)}>
                            <span>Create Item</span>
                            <AddIcon />
                        </Dropdown.Toggle>
                    </Dropdown>
                </div>

                {
                /*CHOOSE*/
                !params?.type && 
                <div className='menuItemsAd' style={{gap: '1rem'}}>
                    <div className='menuBoxSelect' onClick={() => navigate(`/adminpanel/menu/Categories`)}>Categories</div>
                    <div className='menuBoxSelect' onClick={() => navigate(`/adminpanel/menu/Items`)}>Items</div>
                </div>
                }

                {/* CATEGORY DISPLAY */}
                {params?.type?.toLowerCase() === 'categories' &&
                    <div className='menuItemsAd'>
                        {!params?.categoryId && categories && categories.map(item => 
                                <div className='singleMenuAd' key={item.id}>
                                    <div className='descripsionMenuAd'>
                                        <div className='topD'>
                                            <span>{item.title}</span>
                                            
                                        </div>
                                        <div className='descripTextAD'> 
                                            Position: {item.position} <br/>
                                            Is Active : {item.is_active ? 'true' : 'false'}
                                        </div>
                                        <div className='btn__group'>
                                            <div className='changeItemAd' onClick={() => navigate(`/adminpanel/menu/Categories/${item.id}`)}>View</div>
                                            <div className='changeItemAd bg_blue' onClick={() => {setEditCategory(item); setShowCategory(true)}}>Edit</div>
                                            <div className='changeItemAd bg_red' onClick={() => {setEditCategory(item); setShowDelete(true)}}>Delete</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {params?.categoryId && category && category.sub_categories?.map(item => 
                                <div className='singleMenuAd' key={item.id}>
                                    <div className='descripsionMenuAd'>
                                        <div className='topD'>
                                            <span>{item.title}</span>
                                            
                                        </div>
                                        <div className='descripTextAD'> 
                                            Position: {item.position}
                                            <br/>
                                            Is Active : {item.is_active ? 'true' : 'false'}
                                        </div>
                                        <div className='btn__group'>
                                            <div className='changeItemAd'onClick={() => navigate(`/adminpanel/menu/Categories/${item.id}`)}>View</div>
                                            <div className='changeItemAd bg_blue' onClick={() => {setEditCategory(item); setShowCategory(true)}}>Edit</div>
                                            <div className='changeItemAd bg_red' onClick={() => {setEditCategory(item); setShowDelete(true)}}>Delete</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {!categories?.length && <div>No data to display</div>}
                        {category && !category.sub_categories?.length && <div>No sub categories to display</div>}
                    </div>
                }

                {/* ITEMS DISPLAY */}
                {params?.type?.toLowerCase() === 'items' &&
                    <>
                    <Dropdown className='mt-4'>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <span>{selectedCategory}</span>
                            <FilterAltIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleCategorySelect("All")}>All</Dropdown.Item>
                            {categories && categories.map((category) => (
                                <>
                                    <Dropdown.Item
                                        key={category.id}
                                        onClick={() => handleCategorySelect(category.title)}
                                    >
                                        {category.title}
                                    </Dropdown.Item>
                                    {category.sub_categories && category.sub_categories.map(sub => 
                                       <Dropdown.Item
                                            key={sub.id}
                                            onClick={() => handleCategorySelect(sub.title)}
                                        >
                                            {sub.title}
                                        </Dropdown.Item>
                                    )}
                                </>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                        {items &&
                        <div className='menuItemsAd'>
                            {items?.length && 
                                items.filter(x => {
                                    if(selectedCategory !== 'All'){
                                        return x.parent?.title.toLowerCase() === selectedCategory.toLowerCase()
                                    }
                                    return x;
                                }).map(item => 
                                    <div className='singleMenuAd' key={item.id}>
                                        <div className='descripsionMenuAd'>
                                            <div className='imgADm'>
                                                <img src={item.image_url} alt="" />
                                            </div>
                                            <div className='topD'>
                                                <span>{item.title}</span>
                                                <span>{item.parent?.title}</span>
                                            </div>
                                            <div className='descripTextAD'>
                                                Price:  {item.price} MKD<br/> 
                                                Position: {item.position} <br/>
                                                Is Active: {item.is_active ? 'true' : 'false'} <br/>
                                                {item.description} 
                                            </div>
                                            <div className='btn__group'>
                                                <div className='changeItemAd' onClick={() => {setEditItem(item); setShow(true)}}>Edit Item</div>
                                                <div className='changeItemAd bg_red' onClick={() => {setEditItem(item); setShowDelete(true)}}>Delete Item</div>
                                            </div>
                                        </div>
                                </div>
                                )
                            }
                            {!items?.filter(x => {
                                    if(selectedCategory !== 'All'){
                                        return x.parent?.title.toLowerCase() === selectedCategory.toLowerCase()
                                    }
                                    return x;
                                }).length && <div>No data to display</div>}
                        </div>
                        }
                    </>
                }

                {/*ADD NEW MENU CATEGORY */}
                <CreateCategory showCategory={showCategory} setShowCategory={setShowCategory} categories={categories} clearEdit={clearEdit} editCategory={editCategory} updateCategory={updateCategory}/>
                {/*ADD NEW MENU ITEM */}
                <CreateItem show={show} setShow={setShow} categories={categories} clearEdit={clearEdit} editItem={editItem} updateItem={updateItem}/>
                {/*DELETE MENU CATEGORY */}
                <DeleteItem showDelete={showDelete} setShow={setShowDelete} type='category' item={editCategory} clearEdit={clearEdit} updateC={updateCategory}/>
                {/*DELETE MENU ITEM */}
                <DeleteItem showDelete={showDelete} setShow={setShowDelete} type='item' item={editItem} clearEdit={clearEdit} updateC={updateItem}/>
            </div>
        </div>
    );
}

export default MenuAdminPanel;
