import './Contact.css';
import HouseIcon from '@mui/icons-material/House';
import CallIcon from '@mui/icons-material/Call';
import { motion } from 'framer-motion';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function Contact() {
    const titleVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0 },
    };
    return (
        <div className="contact">
            <div className='contactUsS'>
                <motion.div
                    className='titleContact'
                    initial='hidden'
                    animate='visible'
                    variants={titleVariants}
                    transition={{ duration: 1 }}
                    style={{ overflow: 'hidden' }}
                >
                    Contact Us
                </motion.div>
            </div>
            <div className='locatonText'>
                <div className='leftTxtL'>
                    <div className='singleInfoL'>
                        <div className='singleIconL'>
                            <div className='leftsingleIconL'>
                                <HouseIcon />
                                <span>Address</span>
                            </div>
                            <div className='rightsingleIconL'>
                                Kamenot Bogdanci
                            </div>

                        </div>
                    </div>
                    <div className='singleInfoL'>
                        <div className='singleIconL'>
                            <div className='leftsingleIconL'>
                                <CallIcon />
                                <span>Get in Touch</span>
                            </div>
                            <div className='rightsingleIconL'>
                                <span>
                                    +389 71-348-496
                                </span>
                                <span>
                                    info@emeraldbar.mk
                                </span>
                            </div>

                        </div>
                    </div>
                    <div className='singleInfoL'>
                        <div className='singleIconL'>
                            <div className='leftsingleIconL'>
                                <CalendarMonthIcon />
                                <span>Hours</span>
                            </div>
                            <div className='rightsingleIconLDate'>
                                <div className='hoursW'>
                                    <span>Monday to Thursday </span>
                                    <span>7:30 am - 1:00 am</span>
                                </div>
                                <div className='hoursW'>
                                    <span>Friday & Saturday </span>
                                    <span>9:00 am - 5:00 am</span>
                                </div>
                                <div className='hoursW'>
                                    <span>Sunday </span>
                                    <span>9:00 am - 1:00 am</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='rightTxtL'>
                    <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6003.683276741874!2d22.586835!3d41.203426!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13562100646ce4f1%3A0x92e37a6ddd431252!2sEmerald!5e0!3m2!1sen!2sus!4v1717785566305!5m2!1sen!2sus"
                        width="80%"
                        height={350}
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default Contact;
